import React from 'react'
import './card.css'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

import img1 from  './assets/1.png'
import img2 from  './assets/2.png'
import img3 from  './assets/3.png'
import img4 from  './assets/4.png'
import img5 from  './assets/5.png'
import img6 from  './assets/6.png'
import img7 from  './assets/7.png'
import img8 from   './assets/8.png'
import img9 from   './assets/9.png'
import img10 from  './assets/10.png'

import { Pagination, Navigation, Autoplay, EffectCards} from "swiper"

function CardShuffle() {
  return (
    <div className='cards'>
    <Swiper effect={"cards"}
    autoplay={{delay:1000, disableOnInteraction: false}}
    navigation={true}
    grabCursor={true}
    modules={[Pagination, Navigation, Autoplay, EffectCards]}
    className="mySwiper">
        <SwiperSlide><img src={img1}/></SwiperSlide>
        <SwiperSlide><img src={img2}/></SwiperSlide>
        <SwiperSlide><img src={img3}/></SwiperSlide>
        <SwiperSlide><img src={img4}/></SwiperSlide>
        <SwiperSlide><img src={img5}/></SwiperSlide>
        <SwiperSlide><img src={img6}/></SwiperSlide>
        <SwiperSlide><img src={img7}/></SwiperSlide>
        <SwiperSlide><img src={img8}/></SwiperSlide>
        <SwiperSlide><img src={img9}/></SwiperSlide>
        <SwiperSlide><img src={img10}/></SwiperSlide>
        
    </Swiper>
        
    </div>
  )
}

export default CardShuffle