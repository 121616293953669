import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './nav.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faDiscord} from '@fortawesome/free-brands-svg-icons'
import { faTelegram } from '@fortawesome/free-brands-svg-icons'
import {Twitter} from './link.json'
import {Instagram} from './link.json'
import {Telegram} from './link.json'
import {Discord} from './link.json'
import {Facebook} from './link.json'
import {Mail_Page} from './link.json'



function Navigation() {
  return (
    <section className='Nav'>
        <div className="Navbar">
          <ul className='Menu'>


            <li className='Menu-item'><a target={'_blank'} href={Twitter}><FontAwesomeIcon icon={faTwitter} /></a></li>
            <li className='Menu-item'><a target={'_blank'} href={Instagram}><FontAwesomeIcon icon={faInstagram} /></a></li>
            <li className='Menu-item'><a target={'_blank'} href={Discord}><FontAwesomeIcon icon={faDiscord} /></a></li>
            <li className='Menu-item'><a target={'_blank'} href={Telegram}><FontAwesomeIcon icon={faTelegram} /></a></li>
            <li className='Menu-item'><a target={'_blank'} href={Facebook}><FontAwesomeIcon icon={faFacebookF} /></a></li>
                        <li className='Menu-item'><a href={Mail_Page}> <FontAwesomeIcon icon={faEnvelope} /></a></li>
          </ul>
          
        </div>
    </section>
  )
}

export default Navigation