// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
       let price = await store
         .getState()
        .blockchain.smartContract.methods.cost()
         .call();
         let account = await store
         .getState()
         .blockchain.account;
        let freeClaimedStatus = await store
        .getState()
        .blockchain.smartContract.methods.freeMintClaimed(account)
        .call();   
        let maxMintPerTx = await store
        .getState()
        .blockchain.smartContract.methods.maxMintAmountPerTx()
        .call();
        let maxSupply = await store
        .getState()
        .blockchain.smartContract.methods.maxSupply()
        .call();
        let saleStatus = await store 
        .getState()
        .blockchain.smartContract.methods.paused()
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
           price,
           freeClaimedStatus,
           maxMintPerTx,
           maxSupply,
           saleStatus
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
