import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from './redux/blockchain/blockchainActions'
import { fetchData } from './redux/data/dataActions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import * as s from './styles/globalStyles'
import styled from 'styled-components'
import './fonts/Koulen-Regular.ttf'
import  './fonts/NotoSans-Black.ttf'
import './fonts/fonts.css'
import { generateMerkleProof } from './whitelist/merkle-tree'
import CardShuffle from './card/card.js'
import Navigation from './nav/nav.js';


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input

export const StyledButton = styled.button`
  padding: 15px;
  border-radius: 10px;
  border: none;
  background-color: var(--button-color);
  padding: 15px;
  font-weight: bold;
  color: var(--button-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--button-color);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--button-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`

export const StyledImg = styled.img`
  background-color: var(--accent);
  border-radius: 10%;
  width: 250px;
  float: left;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`

export const StyledLink = styled.a`
  color: var(--scanlink);
  text-decoration: none;
`

function App() {
  let [over, setOver] = React.useState(false);

  let titlestyle = {
    color: '',

  }

  const dispatch = useDispatch()
  const blockchain = useSelector((state) => state.blockchain)
  const data = useSelector((state) => state.data)
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`)
  const [mintAmount, setMintAmount] = useState(1)
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0,
    },
    NFT_NAME: '',
    SYMBOL: '',
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    SHOW_BACKGROUND: false,
    "WLENABLED": false
  })

  let cost = (data.cost)
  var totalCostWei
  if((data.freeClaimedStatus)== true){
    totalCostWei = String(cost * mintAmount)
  }
  else{
    if(mintAmount> 1){
      totalCostWei = String(cost * (mintAmount -1))
      }
      else{
        totalCostWei = String(0)
      }
  }
  const claimNFTs = () => {
    let gasLimit = CONFIG.GAS_LIMIT
    let totalGasLimit = String(gasLimit)
    console.log('Cost: ', totalCostWei)
    console.log('Gas limit: ', totalGasLimit)
    console.log(cost)
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
      const merkleProof = generateMerkleProof(blockchain.account);
      blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit ${CONFIG.MARKETPLACE} to view it.`,
        )
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })    

  
  }



  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1
    if (newMintAmount < 1) {
      newMintAmount = 1
    }
    setMintAmount(newMintAmount)
  }

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1
    if (newMintAmount > Number(data.maxMintPerTx)) {
      newMintAmount = Number(data.maxMintPerTx)
    }
    setMintAmount(newMintAmount)
  }

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }
  }

  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    getData((data.cost))
  }, [blockchain.account])


  var ethcost = cost/ 1000000000000000000;
  var totalCost = totalCostWei / 1000000000000000000;
  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={'center'}
        style={{ padding: 24, backgroundColor: 'var(--primary)', fontFamily: 'NotoSans-Black'}}
        image={'/config/images/bg.png'}
      >
       
        <Navigation/>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24, margin: 50 }} test>
          <s.Container3 flex={1} jc={'center'} ai={'center'}>
          <CardShuffle/>
          </s.Container3>
          <s.SpacerLarge />
          <s.Container2
            flex={1}
            jc={'left'}
            ai={'left'}
            style={{
              backgroundColor: 'var(--background)',
              padding: 24,
              borderRadius: 20,
              
            }}
          >
            {' '}
            
            <s.TextTitle
              style={{
                position: 'center',
                textAlign: 'center',
                fontSize: 50,
                fontWeight: 'bold',
                fontFamily: 'NotoSans-Black',
                color: 'var(--accent-text)',
              }}
            >
              Ready for Catchy Chams NFT
            </s.TextTitle>
            <s.TextTitle
              style={{
                position: 'center',
                textAlign: 'center',
                fontSize: 30,
                fontWeight: 'bold',
                fontFamily: 'NotoSans-Black',
                color: 'var(--accent-text)',
              }}
            >
             Supply Left - {(data.maxSupply)-(data.totalSupply)}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: 'center',
                color: 'var(--primary-text)',
              }}
            > 
              <StyledLink target={'_blank'} style={{fontFamily: 'NotoSans-Black',textAlign: 'center'}} href={CONFIG.SCAN_LINK}>
              You can also mint directly from Scan {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= (data.maxSupply) ? (
              <>
                <s.TextTitle
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: 'center', color: 'var(--accent-text)' }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink style={{ textAlign: 'center'}} target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE} 
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: 'center', color: 'var(--accent-text)', fontSize: 30 }}
                >
                  
                  {mintAmount}x {ethcost}{' '}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>

                <s.TextTitle
                  style={{ textAlign: 'center', color: 'var(--accent-text)', fontSize: 50}}
                >
                  
                  {totalCost}{' '}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>

                <s.TextTitle
                  style={{ textAlign: 'center', color: 'var(--accent-text)', fontSize: 30 }}
                >
                  
                  Sale Phase: Public
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.SpacerSmall />
                {blockchain.account === '' ||
                  blockchain.smartContract === null ? (
                  <s.Container ai={'center'} jc={'center'}>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch(connect())
                        getData()
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== '' ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: 'center',
                            color: 'var(--accent-text)',
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: 'center',
                        color: 'var(--accent-text)',
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={'center'} jc={'center'} fd={'row'}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          decrementMintAmount()
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          if ((data.saleStatus) == false) {
                                claimNFTs()
                          }
                          
                        else{
                          setFeedback('Public Sale not enabled')
                        }

                          getData()
                        }}
                      >
                        {claimingNft ? 'BUSY' : 'MINT'}
                      </StyledButton>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault()
                          incrementMintAmount()
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={'center'} jc={'center'} fd={'row'}>

                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container2>
          <s.SpacerLarge />
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={'center'} ai={'center'} style={{ width: '70%' }}>
          <s.TextDescription
            href={CONFIG.LINKTREE} target={'_blank'}
            style={{
              textAlign: 'center',
              color: 'var(--accent-text)',
              fontFamily: 'NotoSans-Black',
              fontWeight: 'bold'
            }}
          >
            <StyledLink target={'_blank'} href={CONFIG.LINKTREE} >
             All rights reserved {CONFIG.NFT_NAME}| Made with <FontAwesomeIcon icon={faHeart} /> by SnowStudios</StyledLink>
          </s.TextDescription>
          <s.SpacerSmall />
        </s.Container>
      </s.Container>
    </s.Screen>
  )
}
export default App
